import React, { useEffect, useRef, useState } from 'react'
import { ArrowButton, ChequeImage, DownloadButton, DownloadContainer, FileCounter, FilterHeader, Header, InputCount, StyledButton, StyledDiv, StyledInput, StyledTable, SyledBody } from './style';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight, Download, Filter } from 'react-feather';
import ChequeOcr from './ChequeOcr';
import ChequeOutput from './ChequeOutput';
import { chequeDataObject } from './constant';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Table } from 'reactstrap';

const Home = () => {
    const fileInputRef = useRef(null); 
    const accountNoRef = useRef(null);
    const accountHolderRef = useRef(null);
    const [fileIndex, setFileIndex] = useState(-1)  
    const [activeField,setActiveField]=useState('accountname')
    const [filteredData, setFilteredData] = useState(null) 
    const [finalData, setFinalData] = useState([]) 
    const [chequeFile, setChequeFile] = useState(null);
    const [textContent,setTextContent]=useState()
    const [accountNo,setAccountNo]=useState('')
    const [accountHolder,setAccountHolder]=useState('')
    const [prevAccountHolder,setPrevAccountHolder]=useState('')
    const [filter,setFilter]=useState('all')
    const [noData,setNoData]=useState(false)
    const [inputCount,setInputCount]=useState(fileIndex+1)
    const xxx='xxx' 
    const nameNotClear='DRAWEE NAME NOT CLEAR'

    useEffect(() => {
        accountHolderRef?.current.focus()
        function handleKeyPress(event) {
          if(event.key==='Tab'){
            event.preventDefault();
            if(activeField==='accountno'){
                accountHolderRef?.current.focus()
                setActiveField('accountname')
            }
            else{
                accountNoRef?.current.focus()
                setActiveField('accountno')
            }
          } else if(event.key==='F1'){
            event.preventDefault();
            setAccountHolder(prevAccountHolder)            
          } else if(event.key==='F2'){
            event.preventDefault();            
            setAccountHolder(xxx)           
          } else if(event.key==='F3'){
            event.preventDefault();
            setAccountHolder(nameNotClear)
          }
        };
        document.addEventListener('keydown', handleKeyPress);
    
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
    }, [prevAccountHolder]);

    const onEnterPress=(e, field) => {
        if (e.keyCode === 13) {
            saveDetails()
        }
      }

    const saveDetails=(type)=>{
        if(fileIndex <0 || fileIndex>=filteredData.length || (type !=='left' && accountHolder === '')) return  
        const id=filteredData[fileIndex]?.id
        const updatedObj={...finalData.filter((d)=>d.id===id)[0], accountNo:accountNo, accountHolder:accountHolder}
        const newData= finalData.map((d)=> d.id===updatedObj.id? updatedObj: d)
        setFinalData([...newData])
        if(accountHolder!=='')setPrevAccountHolder(accountHolder)
        if(type && type==='left'){
            setFileIndex((prev)=> {
                const value=prev===0?prev:prev-1
                resetData(value)            
                setInputCount(value+1)
                return value
            })
        }else{
            setFileIndex((prev)=> {
                const value=prev===filteredData.length-1?prev:prev+1
                resetData(value)            
                setInputCount(value+1)
                return value
            })
        }
        return true
    }

    useEffect(()=>{
        if(textContent && chequeFile){
            const lines=textContent.split('\n')
            const jsonData= lines.map((row, i)=>{
                const rowData = row.split(',')
                const chqName=rowData[5]?.replace(/\r?\n|\r/, "")
                
                return {
                    ...chequeDataObject,
                    id:(i+1),
                    date:rowData[0],
                    data1:rowData[1],
                    chequeNo:rowData[2],
                    data2:rowData[3],
                    amount:rowData[4],
                    chequeName:chqName,                    
                    accountHolder:rowData[6]||'',
                    accountNo:rowData[7]||'',
                    file:Array.from(chequeFile).filter((chq)=>chq.name===chqName)
                }
            })
            const fData=jsonData.filter((d)=>d.date !=='')
            setFinalData(fData)
            const filData=getFilterData(fData)
            setFilteredData(filData, filter)
            if(filData.length>0) {
                setInputCount(1)
                setFileIndex(0)
            }
        }
    },[textContent,chequeFile])

    const getFilterData=(jsonObj, filterOption)=>{
        switch (filterOption) {
            case '':
                return jsonObj.filter((d)=>d.accountHolder.toLowerCase()==='')
            case 'xxx':
                return jsonObj.filter((d)=>d.accountHolder.toLowerCase()==='xxx')
            case 'notclear':
                return jsonObj.filter((d)=>d.accountHolder.toUpperCase()==='DRAWEE NAME NOT CLEAR')
            default:
                return jsonObj;
        }
    }

    const handleFileChange = (e) => {
        const txtFile =Array.from(e.target.files).filter((file)=>file.type==='text/plain')
        if(txtFile && txtFile.length>0){
            const chqImages=Array.from(e.target.files).filter((file)=>file.type!=='text/plain')
            setChequeFile(chqImages)
            readFile(txtFile[0])
        }
    };

    const onRightArrowClick=()=>{
        if(filteredData ===null || filteredData.length <=0) return
        saveDetails()
    }
    
    const onLeftArrowClick=()=>{
        if(filteredData === null || filteredData.length <=0) return
        saveDetails('left')
    }

    const resetData=(index, data)=>{
        const obj=data || filteredData
        const findalDataObject=finalData.filter((d)=>d.id===obj[index]?.id)[0]
        setAccountNo(findalDataObject?.accountNo||'')
        setAccountHolder(findalDataObject?.accountHolder||'')
        accountHolderRef?.current?.focus()
    }

    useEffect(()=>{
        if(selectAllText()){
            accountHolderRef?.current?.select()
        }
    },[accountHolder])

    const selectAllText=()=>{
        return accountHolder && accountHolder !=='' && (
            (filter==='xxx' && accountHolder===xxx) || (filter==='notclear' && accountHolder===nameNotClear))
    }

    const readFile = (selectedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          const contents = reader.result;
          // Write the contents of the file to a new text file. 
          setTextContent(contents)
        };
        reader.readAsText(selectedFile);
    };    

    const getOcrData=(text)=> { 
        text=text.toUpperCase().replace('FOR','').trim()
        const isBankInText = text.toLowerCase().includes('bank')
        if (isBankInText) {
            const shouldContinue = window.confirm('Account holder name contains "bank". Do you want to continue?');
            activeField === 'accountno' ? setAccountNo(shouldContinue ? text : '') : setAccountHolder(shouldContinue ? text : '');
        } else {
            activeField === 'accountno' ? setAccountNo(text) : setAccountHolder(text);
        }       
    }

    const onInputCountChange=(e)=>{
        const val=Math.trunc(e.target.value)
        // 
        setInputCount(val)     
    }    

    const onInputEnterPress=(e) => {
        if (e.key === 'Enter') {
            const val=Math.trunc(e.target.value)
            const filterLength=filteredData?.length||0
            if(val > filterLength) return
            if(val < 1 || val > filteredData.length){
                setInputCount(fileIndex+1);
            }else{
                setFileIndex(val-1)
                resetData(val-1)
            }
        }
      }

    const getDownloadData=()=>{
        return finalData.map((d)=> {
            return {
                date:d.date,
                data1:d.data1,
                chequeNo:d.chequeNo,
                data2:d.data2,
                amount:d.amount,    
                chequeName:d.chequeName,
                accountHolder:d.accountHolder,
                accountNo:d.accountNo
                
            }
        })
    }

    const downloadTextFile = () => {
        const link = document.createElement("a");
        const content = convertJsonArrayToString(getDownloadData());
        const file = new Blob([content], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        link.download = `Text-${Date.now()}.txt`
        link.click();
        URL.revokeObjectURL(link.href);
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getDownloadData());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Buffer to store the generated Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    
        saveAs(blob, `Excel-${Date.now()}.xlsx`);
    };

    function convertJsonArrayToString(jsonArray) {
        // Convert JSON array to string
        const jsonString = JSON.stringify(jsonArray);
      
        // Parse the JSON string back to an array
        const parsedArray = JSON.parse(jsonString);
      
        // Create the desired formatted string
        const formattedString = parsedArray.map(item => Object.values(item).join(',')).join('\n');
      
        return formattedString;
    }

    useEffect(()=>{
        if(filteredData && filteredData.length>0){
            const a =getFilterData(finalData, filter)
            if(a && a.length===0){
                setNoData(true)
            } else {
                setNoData(false)
                setFilteredData(a)
                setFileIndex(0)
                setInputCount(1)
                resetData(0,a)
            }
        }        
    },[filter])

    const setFilterOnRadio=(value)=>{       
        setFilter(value)
    }

    //   const handleAccountNoPaste = (event) => {
    //     setAccountNo(event.clipboardData.getData("text"));
    //   }

    //   const handleAccountNamePaste = (event) => {
    //     console.log(event.clipboardData.getData("text"));
    //     setAccountHolder(event.clipboardData.getData("text"));
    //   }

    const onLeftDoubleArrowClick=()=>{
        if(filteredData ===null || filteredData.length <=0) return
        setFileIndex(0)
        setInputCount(1)
    }
    const onRightDoubleArrowClick=()=>{
        if(filteredData === null || filteredData.length <=0) return
        setFileIndex(filteredData.length-1)
        setInputCount(filteredData.length)
    }

  return (
    <StyledDiv>              
        <SyledBody>
            <div>
                {fileIndex >=0 && filteredData && filteredData.length>0 ? 
                <ChequeOcr chequeData={filteredData[fileIndex]} noData={noData} getOcrData={getOcrData}/>
                : <>
                    <input
                        type="file" 
                        multiple 
                        accept='.txt,.tiff' 
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <ChequeImage onClick={()=> fileInputRef?.current.click()}>
                        <div>Click to Upload</div>
                        {filteredData !==null && filteredData.length===0 && <div style={{color: 'red', fontSize:'14px'}}>No data found!</div>}
                    </ChequeImage>     
                 </>}
            </div>
            <Header>
                <DownloadContainer>
                    <Table style={{textAlign:'justify',textWrap: 'wrap'}}>
                        <thead>
                            <tr>
                                <th>Count</th>
                                <th colSpan={2}><FilterHeader>| Filter <Filter size={14}/></FilterHeader></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{getFilterData(finalData,'all').length}</td>
                                <td><input type="radio" name="filter" checked={filter === 'all'} onChange={()=>setFilterOnRadio('all')}/></td>
                                <td>All</td>
                            </tr>
                            <tr>
                                <td>{getFilterData(finalData,'').length}</td>
                                <td><input type="radio" name="filter" checked={filter === ''} onChange={()=>setFilterOnRadio('')}/></td>
                                <td>Blank</td>
                            </tr>
                            <tr>
                                <td>{getFilterData(finalData,'xxx').length}</td>
                                <td><input type="radio" name="filter" checked={filter === 'xxx'} onChange={()=>setFilterOnRadio('xxx')}/></td>
                                <td>xxx</td>
                            </tr>
                            <tr>
                                <td>{getFilterData(finalData,'notclear').length}</td>
                                <td><input type="radio" name="filter" checked={filter === 'notclear'} onChange={()=>setFilterOnRadio('notclear')}/></td>
                                <td>DRAWEE NAME NOT CLEAR</td>
                            </tr>
                        </tbody>
                    </Table>
                </DownloadContainer>
                <DownloadContainer>
                <Table style={{textAlign:'justify',textWrap: 'wrap',border: '1px solid black'}}>
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><StyledButton onClick={()=>setAccountHolder(prevAccountHolder)}>F1</StyledButton></td>
                                <td>Previous account holder</td>
                            </tr>
                            <tr>
                                <td><StyledButton onClick={()=>setAccountHolder(xxx)}>F2</StyledButton></td>
                                <td>xxx</td>
                            </tr>
                            <tr>
                                <td><StyledButton onClick={()=>setAccountHolder(nameNotClear)}>F3</StyledButton></td>
                                <td>DRAWEE NAME NOT CLEAR</td>
                            </tr>
                        </tbody>
                    </Table>
                </DownloadContainer>
                {!noData && (
                    <div>
                        <FileCounter>
                            {/* <ArrowButton disabled={fileIndex<=0} onClick={onLeftDoubleArrowClick}>
                                <ChevronsLeft size={34} color={'#000'}/>
                            </ArrowButton> */}
                            <ArrowButton disabled={fileIndex<=0} onClick={fileIndex <=0 ? null : onLeftArrowClick}>
                                <ChevronLeft size={32} color={'gray'}/>
                            </ArrowButton>
                            <InputCount value={inputCount} onChange={onInputCountChange} onKeyDown={(e)=>onInputEnterPress(e)} type='number'/>   
                            <ArrowButton disabled={fileIndex <0 || fileIndex>=filteredData?.length} onClick={fileIndex <0 || fileIndex >= filteredData?.length ? null : onRightArrowClick}>
                                <ChevronRight size={34} color={'gray'}/>
                            </ArrowButton>
                            {/* <ArrowButton disabled={fileIndex <0 || fileIndex>=filteredData?.length} onClick={onRightDoubleArrowClick}>
                                <ChevronsRight size={34} color={'#000'}/>
                            </ArrowButton> */}
                        </FileCounter>
                        <div>{filteredData ? filteredData?.length:0}</div>
                    </div>)}
                <DownloadContainer>
                    <DownloadButton onClick={downloadTextFile}>Text <Download size={14}/></DownloadButton>
                    <DownloadButton color='#508d51' onClick={exportToExcel}>Excel <Download size={14}/></DownloadButton>
                </DownloadContainer>
                
            </Header>
        </SyledBody>
        {!noData && (
            <>
                <div>
                    <StyledTable>
                        <tbody>
                            <tr>
                                <td>Account No.<StyledInput ref={accountNoRef} onFocus={() => setActiveField('accountno')} value={accountNo} onKeyDown={(e)=>onEnterPress(e,'accountno')} onChange={(e)=> setAccountNo(e.target.value)} borderColor={activeField==='accountno'?'green':'#ccc'}/></td>
                                <td>Account Holder
                                    <StyledInput ref={accountHolderRef} 
                                        onFocus={(e) => { 
                                            setActiveField('accountname')
                                            }}  
                                        value={accountHolder} onKeyDown={(e)=>onEnterPress(e,'accountname')} onChange={(e)=> setAccountHolder(e.target.value.toUpperCase())} borderColor={activeField==='accountname'?'green':'#ccc'}/>
                                </td>                        
                            </tr>
                        </tbody>
                    </StyledTable>
                </div>
                <ChequeOutput chqDetail={finalData.filter((d)=>d.id===filteredData[fileIndex]?.id)[0]}/>    
            </>
            )
        }
        
    </StyledDiv>
  );
};

export default Home;