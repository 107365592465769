
import React, { useState, useRef, useEffect } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import './css/style.css'
import { imgPreview } from "./utility/ImagePreview";
import { useDebounceEffect } from "./utility/useDebounceEffect";
import { ArrowButton, StyledImg, StyledNoDataDiv } from "./style";
import Tesseract from "tesseract.js";
import { RefreshCw } from "react-feather";

const ChequeOcr=({chequeData, getOcrData, noData})=> {
  const [imgSrc, setImgSrc] = useState("");
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale] = useState(1);
  const [rotate, setRotate] = useState(0);
  //const [aspect, setAspect] = useState(24 / 4);  
  const [aspect, setAspect] = useState(24 / 4);  

  useEffect(()=>{
    setRotate(0)
  },[chequeData])

  function topBottomAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect
  ) {
    const a =makeAspectCrop(
      {
        unit: "%",
        width: 22,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    )
    const b =centerCrop(a,mediaWidth,mediaHeight)
    const finalObj={...b,x:b.x*1.9,y:b.y*1.5}
    //const finalObj={...b,x:b.x/2.1,y:b.y*1.1}
    return finalObj;
  }

  useEffect(()=>{
    onFileLoad()
  },[chequeData])

  function onFileLoad() {
    if (chequeData.file && chequeData.file.length>0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || ""),
      );
      reader.readAsDataURL(chequeData.file[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(topBottomAspectCrop(width, height, aspect));
      setAspect(undefined);
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current 
      ) {
        const cropImgURL=await imgPreview(
          imgRef.current,
          completedCrop,
          scale,
          rotate,
        )
        //setPreviewSrc(cropImgURL)
        recognizeText(cropImgURL)
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  const recognizeText = async (croppedImg) => {
    try{
    if (croppedImg) {
        const result = await Tesseract.recognize(croppedImg,'eng');
        let text=result.data.text.toUpperCase()
        text=text.replace(/&/g, "AND");
        text = text.replace(/[^a-zA-Z0-9 ]/g, " ")
        text = text.replace(/ +/g, ' ').trim()
        const wordToRemove='FOR'
        if (text.toUpperCase().startsWith(wordToRemove)) {
          text = text.slice(wordToRemove.length).trim()
        }      
        getOcrData(text);
      }
    }
    catch(ex){
      console.error(ex);
      getOcrData('')
    }
  }

  return (
    <div style={{display:'flex'}}>
      <div style={{display: `${noData?'none':'flex'}`, alignItems: 'center', gap: '5px'}}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minWidth={20}
            minHeight={10}
            // circularCrop
          >
            <StyledImg
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)`}}
              onLoad={onImageLoad}
            />
          </ReactCrop>  
          <ArrowButton onClick={()=> rotate===0?setRotate(180):setRotate(0)}>
            <RefreshCw />   
          </ArrowButton>   
      </div>
    {noData && <StyledNoDataDiv>No Data Found!</StyledNoDataDiv>}
    </div>
  )
}

export default ChequeOcr