import { Table } from "reactstrap"
import styled from "styled-components"

export const ChequeImage=styled.div`
    width: 80vw;
    min-height: 78vh;
    border: 2px dashed #878787;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2rem;
    &:hover {
        background-color: #ededed;
        font-size: 1.3rem;
    }
`

export const Header=styled.div`
    display: flex;
    justify-content: space-between;
    //gap: 40px;
    align-items: center;
    flex-direction: column;
    height: 78vh;
    `

export const SyledBody=styled.div`
    align-self: flex-start;
    display: flex;
    gap:5px;
    width: -webkit-fill-available;
    justify-content: space-evenly;
`
export const DownloadButton=styled.button`
    height: fit-content;
    background-color: ${(props)=> props.color||'gray'};
    color: #fff;
    border-radius: 5px;
    width: -webkit-fill-available;
    cursor: pointer;
    padding:3px 8px;
    display: inline-flex;
    gap:3px;
`
export const StyledDiv=styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
export const FileCounter=styled.div`
    display: flex;
    align-items: center;
`
export const ArrowButton=styled.span`
    cursor: ${(props)=> props.disabled?'not-allowed':'pointer'};  
    disabled: ${(props)=> props.disabled?props.disabled:false};  
`
export const StyledImg=styled.img`
    height: 78vh !important
`

export const StyledNoDataDiv=styled.div`
    width: 80vw;
    display: flex;
    color: red;
    min-height: 78vh;
    align-items: center;
    justify-content: center;
`
export const StyledInput=styled.input`
    width: -webkit-fill-available;
    border: 1px solid ${(props)=> props.borderColor};
    padding: 6px;
    outline: none;
    &:focus {
        border: 1px solid green;
    }
`

export const StyledTable=styled(Table)`
    min-width: 60vw;
`
export const DownloadContainer=styled.div`
    display: flex;
    gap: 5px;
`

export const FilterHeader=styled.div`
    display: flex;
    align-items: center;
`
export const InputCount=styled.input`
    max-width: 60px;
`

export const StyledButton=styled.button`
    cursor: pointer;
    background-color: #ff5e5e;
    border-radius: 4px;
    color: #fff;
`