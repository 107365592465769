const chequeDataObject={
    date:'',
    data1:'',
    chequeNo:'',
    data2:'',
    amount:'',    
    chequeName:'',
    accountNo:'',
    accountHolder:'' ,
    file:null
}

export {chequeDataObject}