import React, { useEffect, useState } from 'react'
import { Card, CardBody, Label, Table } from 'reactstrap';

const ChequeOutput = ({chqDetail}) => {
  return chqDetail ? (     
    <Table style={{width:'-webkit-fill-available', fontSize:'14px'}}>
        <thead>
            <tr>
                {/* <th>Date</th> */}
                {/* <th>Data1</th> */}
                <th>Cheque No.</th>
                {/* <th>Data2</th> */}
                <th>Amount</th>                        
                <th>Account No.</th>
                <th>Account Holder Name</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                {/* <td><Label>{date}</Label></td> */}
                {/* <td><Label>{data1}</Label></td> */}
                <td><Label>{chqDetail.chequeNo}</Label></td>
                {/* <td><Label>{data2}</Label></td> */}
                <td><Label>{chqDetail.amount}</Label></td>
                <td><Label>{chqDetail.accountNo}</Label></td>
                <td><Label>{chqDetail.accountHolder}</Label></td>
            </tr>                
        </tbody>
    </Table>
  ):null
};

export default ChequeOutput